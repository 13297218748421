import React from "react";
import { Button } from "@mui/material";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import "./upload.css";

const imageMaxSize = 20000000; // 20MB

const Upload = ({ handleSave, uploadText }) => {
  const dropRef = React.useRef({});

  // const handleDrag = (event) => {
  //   event.preventDefault();
  //   event.stopPropagation();
  // };

  // const handleDrop = (event) => {
  //   event.preventDefault();
  //   event.stopPropagation();
  //   if (event.dataTransfer.files && event.dataTransfer.files.length > 0) {
  //     saveInputFile(event.dataTransfer.files, dropRef.current);
  //   }
  // };

  // useEffect(() => {
  //   let droppableDiv = dropRef.current;
  //   droppableDiv.addEventListener("dragover", handleDrag);
  //   droppableDiv.addEventListener("drop", handleDrop);
  //   return () => {
  //     droppableDiv.removeEventListener("dragover", handleDrag);
  //     droppableDiv.removeEventListener("drop", handleDrop);
  //   };
  // }, [handleDrop]);

  const isImageSelected = (uploadedImageName) => {
    let listFormats = [
      "pdf",
      "doc",
      "docx",
      "page",
      "csv",
      "pptx",
      "pptm",
      "potx",
      "jpg",
      "jpeg",
      "png",
    ];
    return listFormats.includes(
      uploadedImageName.split(".").reverse()[0].toLocaleLowerCase()
    );
  };

  const saveInputFile = (files) => {
    const isImageUploaded = isImageSelected(files[0].name);
    if (files[0].size < imageMaxSize && isImageUploaded) {
      const fileData = files[0];
      const reader = new FileReader();
      reader.readAsDataURL(fileData);
      reader.onload = () => {
        handleSave(reader.result, files[0]);
      };
    }
  };

  const imageDragDropContainer = (
    <div className="uploadImageFrame" ref={dropRef}>
      <InsertDriveFileIcon fontSize="large" style={{ color: "#999999" }} />
      <p>{uploadText?.uploadDesc}</p>
      <Button variant="outlined" size="medium" component="label">
        {uploadText?.buttonUpload}
        <input
          type="file"
          style={{ display: "none" }}
          onChange={(e) => {
            saveInputFile([...e.target.files]);
            e.target.value = null;
          }}
        />
      </Button>
    </div>
  );

  return <div className="upload-document">{imageDragDropContainer}</div>;
};

export default Upload;
