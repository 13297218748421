import * as React from "react";
import dayjs, { Dayjs } from "dayjs";
import arrow from "../../assets/svg/arrow.svg";
import CircularProgress from "@mui/material/CircularProgress";
import checked from "../../assets/svg/checked.svg";
import checked1 from "../../assets/svg/checked1.svg";
import Upload from "../Upload";
import "./index.scss";

interface FormProps {
  settings: any;
}

const Form: React.FC<FormProps> = ({ settings }) => {
  const [startDate, setStartDate] = React.useState<Dayjs | null>(dayjs());
  const [endDate, setEndDate] = React.useState<Dayjs | null>(
    dayjs().add(7, "week")
  );
  const [service, setService] = React.useState("");
  const [source, setSource] = React.useState("");
  const [sent, setSent] = React.useState(false);
  const [load, setLoad] = React.useState(false);
  const [fileData, setFileData] = React.useState("");
  const [fileName, setFileName] = React.useState("");
  const [form, setForm] = React.useState({
    fullname: "",
    email: "",
    details: "",
    other: "",
    orderid: "",
  });

  const handleForm = (event: any, name: any) => {
    setForm({ ...form, [name]: event.target.value });
  };

  const handleDocument = (value: string, file: any) => {
    setFileData(value);
    setFileName(file.name);
  };

  const handleSendData = () => {
    setLoad(true);
    setTimeout(() => {
      setLoad(false);
      setSent(true);
      setForm({
        fullname: "",
        email: "",
        details: "",
        other: "",
        orderid: "",
      });
      setSource("");
      setService("");
      setStartDate(dayjs());
      setEndDate(dayjs().add(7, "week"));
      setFileData("");
      setFileName("");

      console.log("form", form);
      console.log("fileName", fileName);
      console.log("fileData", fileData);
      console.log("service", service);
      console.log("source", source);
    }, 2000);
  };

  const handleSubmit = async (e: any) => {
    setLoad(true);
    try {
      const response = await fetch("/api/sendEmail", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name: form.fullname || "No Name",
          email: form.email || "hello@mathbikes.com",
          message: form.details || "No Message",
          reasor: service || "No Reasors",
        }),
      });
      if (response.ok) {
        setLoad(false);
        setSent(true);
        setForm({
          fullname: "",
          email: "",
          details: "",
          other: "",
          orderid: "",
        });
        setSource("");
        setService("");
        setStartDate(dayjs());
        setEndDate(dayjs().add(7, "week"));
        setFileData("");
        setFileName("");
      } else {
        console.log("Failed to send email.");
      }
    } catch (error) {
      console.log("Error occurred while sending the email.");
    }
  };

  return (
    <section className="form">
      <div className="container">
        <h2>{settings?.titleForm}</h2>
        <p className="form-desc-p">{settings?.contentForm}</p>

        <h3>{settings?.section1}</h3>
        <div className="form-section">
          <div className="form-line full-form-line">
            <label>
              {settings?.field1} <span>*</span>
            </label>
            <input
              type="text"
              value={form?.fullname}
              onChange={(e) => handleForm(e, "fullname")}
            />
          </div>
          <div className="form-line full-form-line">
            <label>
              {settings?.field2} <span>*</span>
            </label>
            <input
              type="email"
              value={form?.email}
              onChange={(e) => handleForm(e, "email")}
            />
          </div>
        </div>
        <h3>{settings?.section2}</h3>
        <div className="form-section">
          <div className="answers-options">
            {settings?.servicesList?.map((e: any, i: any) => {
              return (
                <div
                  key={i}
                  className={`${
                    service === e?.name
                      ? "answers-options-item active"
                      : "answers-options-item"
                  }`}
                  onClick={() => setService(e?.name)}
                >
                  <img
                    src={service === e?.name ? checked1 : checked}
                    alt={e?.name}
                  />
                  {e?.name}
                </div>
              );
            })}
          </div>
          <div className="form-line full-form-line-all">
            <label>
              {settings?.field4} <span>*</span>
            </label>
            <textarea
              onChange={(e) => handleForm(e, "details")}
              value={form?.details}
            ></textarea>
          </div>
          <div className="form-line full-form-line-all">
            <label>{settings?.field5}</label>
            <Upload handleSave={handleDocument} uploadText={settings} />
            <p>{fileName}</p>
          </div>
        </div>
        <div className="button-options">
          {!sent && (
            <>
              {load && <CircularProgress />}
              {!load && (
                <div className="button-options-item" onClick={handleSubmit}>
                  {settings?.button} <img src={arrow} alt="arrow" />
                </div>
              )}
            </>
          )}
          {sent && <p className="form-desc">{settings?.message}</p>}
        </div>
      </div>
    </section>
  );
};

export default Form;
