import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "./index.scss";

interface FQAPropsType {
  settings?: any;
}
interface FQAListPropsType {
  title: string;
  content: string;
  open: boolean;
  content2?: string;
}

const FQA: React.FC<FQAPropsType> = ({ settings }) => {
  const [fqaData] = React.useState(settings?.faqList);

  return (
    <section className="fqa">
      <div className="container">
        <h3>{settings?.fqaSubTitle}</h3>
        <h2>{settings?.fqaTitle}</h2>
        <p className="fqa-desc">{settings?.faqContent}</p>
        <div className="fqa-wrap">
          {fqaData.map((e: FQAListPropsType, i: number) => {
            return (
              <Accordion
                key={i}
                className={e.open ? "fqa-item open" : "fqa-item"}
                disableGutters={true}
                defaultExpanded={i === 0}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={`fqa-content-${i}`}
                  id={`fqa-content-${i}`}
                >
                  <h5>{e.title}</h5>
                </AccordionSummary>
                <AccordionDetails>
                  {e?.content && <p>{e.content}</p>}
                  {e?.content2 && <p>{e.content2}</p>}
                </AccordionDetails>
              </Accordion>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default FQA;
